<template>
  <div>
    <span @click="show = true">
      {{ getType.name }}
    <i class="iconfont icon-chao-xia tu-biao"></i>
    </span>
  </div>

  <van-popup v-model:show="show" teleport="body" :safe-area-inset-bottom="true" :style="{height:'300px'}" round
             position="bottom">
    <van-picker
        v-model="cascaderValue"
        :columns-field-names="{text: 'name'}"
        :columns="list"
        @cancel="show = false"
        @confirm="onChange"
    />
  </van-popup>


</template>

<script>
//项目排序
export default {
  name: 'XiangMuOrderBy',
}
</script>
<script setup>
import {useContext, ref, defineProps, computed, defineEmit} from 'vue'
import _ from 'lodash'

const emit = defineEmit()
const ext = useContext()
let props = defineProps({
  defaultName: String,
  list: {
    type: Array,
    default() {
      return []
    }
  },
})

let cascaderValue = ref('')
let selectValue = ref({})
const show = ref(false);

let getType = computed(() => {
  if (_.isEmpty(selectValue.value)) {

    return {
      name: props.defaultName
    }
  }
  return selectValue.value
})

function onChange(value, index) {
  selectValue.value = value
  show.value = false
  emit('change')
}

ext.expose({
  //获取选中的值
  getValue() {
    return getType.value
  }
})

</script>

<style scoped lang="less">
@import "~@/assets/basis_css/style/var.less";

.tu-biao {
  color: @gray-5;
}
</style>
