<template>
  <home-header fixed/>
  <header-selete
      :style="{top:'47px'}"
      fixed
      :show-return="false"
      ref="selectkeyword"
      placeholder='搜索项目名称'
      @submit="updateList"/>
  <div class="dao-hang">
    <van-tabs v-model:active="pageName" @change="pageChange">
      <van-tab title="找服务" name="FuWuDaTing">
      </van-tab>
      <van-tab title="找项目" name="XiangMuDaTing"></van-tab>
      <van-tab title="案例" name="AnLiDaTing"></van-tab>
    </van-tabs>
    <div class="shai-xuan">
      <div class="van-ellipsis">
        <select-top-address ref="addDiv" @change="updateList"/>
      </div>
      <div class="van-ellipsis">
        <xiang-mu-order-by default-name="需求状态" ref="listOrder" @change="updateList" :list="xiangMuStatus"/>
      </div>
      <div class="van-ellipsis">
        <select-top-fu-wu-type ref="typeDiv" @change="updateList"/>
      </div>
    </div>
  </div>
  <div class="dao-hang-height"></div>

  <list-page ref="xiaLa" @load="getLoad" top="190px" bottom="80px">
    <div
        v-for="(vo) in dataList"
        :key="vo.id"
    >
      <xiang-mu-list
          :to="{name:'XiangMuInfo',params:{xiang_mu_id:vo.id}}"
          :xiang-mu-info="vo"
      />
    </div>
  </list-page>
  <footer-nav fixed path-name='XiangMuDaTing'/>
</template>

<script>
//项目大厅
import _ from 'lodash'
import HomeHeader from "../open/homeHeader";
import {setTitle} from "@/api/GongJu";
import http from "@/api/http";
import HeaderSelete from "@/views/open/headerSelete";
import SelectTopAddress from "@/components/select/selectTopAddress";
import XiangMuOrderBy from "@/views/index/public/XiangMuOrderBy";
import SelectTopFuWuType from "@/components/select/SelectTopFuWuType";
import ListPage from "@/components/list/ListPage";
import XiangMuList from "@/views/index/public/XiangMuList";
import FooterNav from "@/views/open/footerNav";
import XEUtils from "xe-utils";

export default {
  name: 'XiangMuDaTing',
  components: {
    FooterNav,
    XiangMuList, ListPage, SelectTopFuWuType, XiangMuOrderBy, SelectTopAddress, HeaderSelete, HomeHeader
  },
  setup() {
    return {
      XEUtils
    }
  },
  data() {
    return {
      xiangMuStatus: [
        {name: '全部', type: '全部'},
        {name: '进行中', type: '进行中'},
        {name: '已完成', type: '已完成'},
      ],
      dataList: [],
      where: {},
      pageName: 'XiangMuDaTing',
    }
  },
  deactivated() {
    this.setNav()
  },
  mounted() {
    this.setNav()
  },
  methods: {
    setNav() {
      setTitle(_.get(this.$route, 'meta.title'))
      this.pageName = 'XiangMuDaTing'
    },
    getLoad({num, size}) {
      this.where.address_name = this.$refs.addDiv.getAddressName()
      this.where = {...this.where, ...this.$refs.typeDiv.getWhere()}
      this.where.keyword = this.$refs.selectkeyword.getKeyword()
      this.where.page = num
      this.where.data_length = size
      this.where.order_type = _.get(this.$refs.listOrder.getValue(), 'type')

      http.get('/gzhphp/fu_wu_shang/getXiangMuDaTing', {params: this.where, load: false}).then(da => {
        if (da.code == 1) {
          if (num === 1) this.dataList = []
          XEUtils.each(da.data.data, n => {
            this.dataList.push(n)
          })
          this.$refs.xiaLa.endSuccess(da.data.data.length)
        }

      })
    },
    pageChange(page) {
      this.$router.push({name: page})
    },
    updateList(item) {
      this.dataList = []
      this.$refs.xiaLa.reset()
    }
  },
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.xiaLa && vm.$refs.xiaLa.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.xiaLa && this.$refs.xiaLa.beforeRouteLeave()
    next()
  },
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";
</style>


