<template>
  <van-button size="mini" class="can-yu-btn" :class="{'over-status':over}" round>
    <slot></slot>
  </van-button>
</template>

<script>
export default {
  name: 'CanYuXiangMu',
  props:{
    over:Boolean //
  },
  mixins: [],
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.can-yu-btn {
  border-color: @blue;
  border-radius: 7px;
  padding: 3px 10px;
}
.over-status{
  opacity: .5;
}
</style>
