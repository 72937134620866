<template>
  <div class="fu-wu-shang" @click="showInfo">
    <div class="name">
      <div class="title van-multi-ellipsis--l2">
        {{ xiangMuInfo.title }}
      </div>
    </div>
    <div class="fu-wu">
      {{ xiangMuInfo.miao_shu }}
    </div>
    <div class="cheng-jiao">
      <div>
        <biao-qian :list="[xiangMuInfo.type_name,xiangMuInfo.address_name]">{{ xiangMuInfo.type_name }}</biao-qian>
      </div>
      <div class="money">
        {{ getMoney() }}
      </div>
    </div>
    <div class="fa-bu-ren">
      <div class="user-name">
        发布人：{{ xiangMuInfo.user_nickname }}
      </div>
      <div class="can-yu">
        <can-yu-xiang-mu v-if="is_can_yu()">参与项目</can-yu-xiang-mu>
        <can-yu-xiang-mu over v-else>已完成</can-yu-xiang-mu>
      </div>
    </div>
  </div>
</template>

<script>
//项目列表

export default {
  name: 'XiangMuList',
}
</script>
<script setup>
import {ref, defineProps} from 'vue'
import BiaoQian from "../../open/biaoQian";
import CanYuXiangMu from "./CanYuXiangMu";
import {unitConvert} from "../../../api/GongJu";
import XEUtils from "xe-utils";
import {useRouter} from "vue-router";

const router = useRouter()
const props = defineProps({
  xiangMuInfo: Object,
  to: Object
})

function getMoney() {
  if (XEUtils.toInteger(props.xiangMuInfo.money) <= 0) return '可议价'
  else return unitConvert(props.xiangMuInfo.money)
}

//是否已找到服务商 true = 未找到
function is_can_yu() {
  return _.toInteger(props.xiangMuInfo.ywht_id) === 0
}

//跳转到详情
function showInfo() {
  let to = props.to
  XEUtils.set(to, 'query.ran_id', XEUtils.uniqueId('page_'))
  router.push(to)
}

</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.fu-wu-shang {
  margin: 10px;
  background-color: @white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px #ECDDDD;

  .name {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-size: 16px;
      font-weight: 700;
      flex: 1;
    }


  }

  .fu-wu {
    margin-top: 6px;
    color: @gray-6;
  }

  .fa-bu-ren {
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    color: @gray-6;
  }

  .cheng-jiao {
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .money {
      //width: 80px;
      color: @blue;
      font-size: 16px;
    }
  }
}

</style>
